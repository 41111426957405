import React from "react";

import { Content as RadixAccordionContent } from "@radix-ui/react-accordion";
import clsx from "clsx";

import { animation_collapsibleContent } from "~styles/common/animation.collapsibleContent.css";

import { Box } from "~components/Box";

import type { AccordionContentProps as RadixAccordionContentProps } from "@radix-ui/react-accordion";
import type { ReactNode } from "react";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AccordionBaseItemContentProps
  extends GetSprinklesArgs,
    StoryblokBlok,
    Omit<RadixAccordionContentProps, "color"> {
  children: ReactNode;
  className?: string;
}

export function AccordionBaseItemContent({
  className,
  children,
  ...rest
}: AccordionBaseItemContentProps) {
  return (
    <Box
      as={RadixAccordionContent}
      className={clsx(className, animation_collapsibleContent)}
      {...rest}
    >
      {children}
    </Box>
  );
}

import React from "react";

import clsx from "clsx";

import { Box } from "~components/Box";
import { FormHubspotDialog } from "~components/FormHubspotDialog";
import { Link } from "~components/Link";

import { Button } from "../Button/index";
import * as styles from "./styles.css";

import type { ButtonProps } from "../Button/index";
import type { PageProps } from "gatsby";
import type { FormHubspotDialogProps } from "~components/FormHubspotDialog";
import type { VariantBackgroundColorEnum } from "~styles/common/variant.backgroundColor.css";
import type { StoryblokBlok, StoryblokFieldLink } from "~types/storyblok.types";

export interface BannerProps extends StoryblokBlok {
  prefix?: string;
  text: string;
  cta?: string;
  link?: StoryblokFieldLink;
  background: VariantBackgroundColorEnum;
  homepageOnly: boolean;
  location: Partial<PageProps["location"]>;
  ctaButton?: Array<StoryblokBlok>;
  expiresOn: string;
}

const checkIfShowBanner = (
  location: BannerProps["location"],
  homepageOnly: BannerProps["homepageOnly"]
) => {
  const { pathname = "" } = location ?? {};

  // Recognizes homepage paths with or without country/language codes i.e. /it-it/
  const homepagePathRegex = /^\/(|\/[a-z]{2}-[a-z]{2}\/)$/;
  const isHomepage = homepagePathRegex.test(pathname);

  return homepageOnly ? isHomepage : true;
};

export function Banner({
  prefix,
  text,
  background,
  homepageOnly,
  location,
  cta,
  link,
  ctaButton: ctaButtonBlokArray,
  expiresOn,
  ...rest
}: BannerProps) {
  const show = checkIfShowBanner(location, homepageOnly);
  const [ctaButton] = ctaButtonBlokArray || [];

  const buttonProps: ButtonProps = {
    appearance: "tertiary",
  };

  const isExpired = new Date(expiresOn) < new Date();

  if (isExpired) {
    return null;
  }

  if (ctaButton?.component === "FormHubspotDialog") {
    return (
      <Box
        {...rest}
        className={styles.getBannerStyles({ background, show })}
        id="banner"
      >
        <Box className={styles.dialogBanner}>
          <Box as="span" className={clsx(styles.text, styles.prefix)}>
            {prefix}
          </Box>
          <Box as="span" className={styles.text}>
            {text}
          </Box>

          <FormHubspotDialog
            {...(ctaButton as FormHubspotDialogProps)}
            buttonProps={buttonProps}
            marginLeft="spacing2"
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      {...rest}
      className={styles.getBannerStyles({ background, show })}
      id="banner"
    >
      <Link link={link} className={styles.link}>
        <Box className={styles.textWrapper({ background })}>
          <Box as="span" className={clsx(styles.text, styles.prefix)}>
            {prefix}
          </Box>
          <Box as="span" className={styles.text}>
            {text}
          </Box>
          <Button
            background={background}
            appearance="tertiary"
            className={styles.getButtonStyles({ device: "desktop" })}
          >
            <Box as="span" className={styles.cta}>
              {cta}
            </Box>
          </Button>
        </Box>
        <Button
          background={background}
          appearance="tertiary"
          className={styles.getButtonStyles({ device: "mobile" })}
        />
      </Link>
    </Box>
  );
}

import React from "react";

import { getSprinkles } from "~styles/getSprinkles.css";

import { Image } from "~components/Image";

import type { ImageProps } from "~components/Image";
import type { StoryblokFieldMedia } from "~types/storyblok.types";

export interface AvatarProps extends ImageProps {
  image: StoryblokFieldMedia;
}

/**
 * Standardized appearance for avatars (e.g. Authors, Quote Attributions)
 *
 * Note: It may seem redundant to set border radius on both inner and outer
 * elements but it is to ensure consistent in Safari.
 */
export function Avatar({
  image,
  width = "spacing8",
  height = "spacing8",
}: AvatarProps) {
  return (
    <Image
      image={image}
      imgClassName={getSprinkles({
        borderRadius: "50%",
        overflow: "hidden",
      })}
      objectFit="cover"
      flexShrink="0"
      borderRadius="50%"
      // border="default"
      // boxShadow="light"
      width={width}
      height={height}
    />
  );
}

import React from "react";

import { faAngleDown } from "@fortawesome/sharp-regular-svg-icons/faAngleDown";
import { faArrowRightLong } from "@fortawesome/sharp-regular-svg-icons/faArrowRightLong";
import clsx from "clsx";

import { Box } from "~components/Box";
import { ButtonLink } from "~components/ButtonLink";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { StoryblokEditable } from "~components/StoryblokEditable";
import { StoryblokRichText } from "~components/StoryblokRichText";
import { TickList } from "~components/TickList";

import { AccordionBaseItem } from "../AccordionBase/AccordionBaseItem";
import { AccordionBaseItemContent } from "../AccordionBase/AccordionBaseItemContent";
import { AccordionBaseItemTrigger } from "../AccordionBase/AccordionBaseItemTrigger";
import * as styles from "./styles.css";

import type { BoxProps } from "~components/Box";
import type { ButtonLinkProps } from "~components/ButtonLink";
import type { TickListProps } from "~components/TickList";
import type { VariantThemeEnum } from "~styles/themes/variantThemeColorPalette.css";
import type {
  StoryblokBlok,
  StoryblokFieldRichText,
} from "~types/storyblok.types";

export interface AccordionRichTextItemProps
  extends StoryblokBlok,
    Omit<BoxProps, "value" | "onChange"> {
  bloks?: Array<StoryblokBlok>;
  buttonLink?: Array<StoryblokBlok & ButtonLinkProps>;
  description?: StoryblokFieldRichText;
  index: number;
  onChange?: (index: number) => void;
  shouldShowNumbers?: boolean;
  theme?: VariantThemeEnum;
  tickList?: Array<StoryblokBlok & TickListProps>;
  title?: string;
}

const formatNumber = (number: number) => {
  return number ? number.toString().padStart(2, "0") : null;
};

export function AccordionRichTextItem({
  bloks,
  buttonLink: buttonLinkBlokArray,
  description,
  index,
  shouldShowNumbers,
  tickList: tickListBlokArray,
  title,
  ...rest
}: AccordionRichTextItemProps) {
  const [buttonLink] = buttonLinkBlokArray || [];
  const [tickList] = tickListBlokArray || [];

  return (
    <AccordionBaseItem className={styles.item} index={index} {...rest}>
      <Box as={AccordionBaseItemTrigger} className={styles.trigger}>
        {shouldShowNumbers && (
          <Box className={styles.number}>{formatNumber(index + 1)}</Box>
        )}

        {title && (
          <Box
            textAppearance="h6"
            as="div"
            width="100%"
            fontWeight="semibold"
            className={styles.triggerTitle}
          >
            {title}
          </Box>
        )}

        <IconFontAwesome
          size="1x"
          icon={faAngleDown}
          className={styles.accordionIcon}
          marginLeft="auto"
        />
      </Box>

      {(description || bloks) && (
        <AccordionBaseItemContent
          className={clsx({
            [styles.offsetContentForNumber]: shouldShowNumbers,
          })}
        >
          {description && (
            <StoryblokRichText
              as="dd"
              marginBottom="spacing2"
              textAppearance="body_lg"
              text={description}
            />
          )}

          {tickList && (
            <StoryblokEditable {...tickList}>
              <TickList marginBottom="spacing2" {...tickList} />
            </StoryblokEditable>
          )}

          {buttonLink && (
            <StoryblokEditable {...buttonLink}>
              <ButtonLink
                marginBottom="spacing2"
                iconLeft={faArrowRightLong}
                {...buttonLink}
              />
            </StoryblokEditable>
          )}
        </AccordionBaseItemContent>
      )}
    </AccordionBaseItem>
  );
}

import React from "react";

import { Item as RadixAccordionItem } from "@radix-ui/react-accordion";

import type { ReactNode } from "react";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AccordionBaseItemProps extends StoryblokBlok {
  children: ReactNode;
  className?: string;
  index: number;
  value?: string;
}

export function AccordionBaseItem({
  index,
  className,
  children,
  value,
  ...rest
}: AccordionBaseItemProps) {
  return (
    <RadixAccordionItem
      className={className}
      value={value || index.toString()}
      {...rest}
    >
      {children}
    </RadixAccordionItem>
  );
}

import React from "react";

import {
  Header as RadixAccordionHeader,
  Trigger as RadixAccordionTrigger,
} from "@radix-ui/react-accordion";
import clsx from "clsx";

import type { AccordionTriggerProps as RadixAccordionTriggerProps } from "@radix-ui/react-accordion";
import type { ReactNode } from "react";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AccordionBaseItemTriggerProps
  extends StoryblokBlok,
    RadixAccordionTriggerProps {
  asChild?: boolean;
  children: ReactNode;
  className?: string;
}

export function AccordionBaseItemTrigger({
  children,
  className,
  asChild,
  onClick,
}: AccordionBaseItemTriggerProps) {
  return (
    <RadixAccordionHeader asChild>
      <RadixAccordionTrigger
        className={clsx(className)}
        asChild={asChild}
        onClick={onClick}
      >
        {children}
      </RadixAccordionTrigger>
    </RadixAccordionHeader>
  );
}

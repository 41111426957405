import React, { forwardRef } from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import { Root as RadixAccordionRoot } from "@radix-ui/react-accordion";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import type { ReactNode, Ref } from "react";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AccordionBaseProps extends GetSprinklesArgs, StoryblokBlok {
  children?: ReactNode | Array<ReactNode>;
  className?: string;
  collapsible?: boolean;
  defaultValue?: `${number}`;
  onValueChange?(value: string): void;
  value?: string;
}
export const AccordionBase = forwardRef(
  (
    {
      children,
      onValueChange,
      value,
      className: userClassName,
      ...rest
    }: AccordionBaseProps,
    ref: Ref<HTMLDivElement> | undefined
  ) => {
    const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);
    const rootClassNames = clsx(userClassName, getSprinkles(atomProps));

    return (
      <RadixAccordionRoot
        className={rootClassNames}
        type="single"
        onValueChange={onValueChange}
        value={value}
        ref={ref}
        {...otherProps}
      >
        {children}
      </RadixAccordionRoot>
    );
  }
);

import React, { useContext } from "react";

import loadable from "@loadable/component";

import { transformStoryblokEntryNodeToTag } from "~utils/transformers/transformStoryblokEntryNodeToTag";

import { Box } from "~components/Box";
import { Loader } from "~components/Loader";

import { PageQueryContext } from "~context/PageQueryContext";

import type { TagLinkProps } from "~components/Tag/TagLink";

export interface BlogPostCategoriesProps extends TagLinkProps {
  isCentered?: boolean;
}

const TagLink = loadable(
  () => {
    return import("~components/Tag/TagLink").then((mod) => {
      return mod.TagLink;
    });
  },
  {
    fallback: <Loader width="fallbackTagWidth" />,
  }
);

export function BlogPostCategories({
  tagSize: size = "sm",
  isCentered = false,
  gap = "spacing1",
}: BlogPostCategoriesProps) {
  const { data } = useContext(PageQueryContext) || {};
  const { tagsItems: tagsItemsData } = data ?? {};
  const { nodes: tagsItems } = tagsItemsData || {};
  if (!Array.isArray(tagsItems) || tagsItems.length === 0) return null;

  return (
    <Box
      display="flex"
      gap={gap}
      flexWrap="wrap"
      justifyContent={isCentered ? "center" : "start"}
    >
      {tagsItems.map((tagItem) => {
        return (
          <TagLink
            key={tagItem.uuid}
            tagSize={size}
            {...transformStoryblokEntryNodeToTag(tagItem)}
          />
        );
      })}
    </Box>
  );
}

import React from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import { checkHasLength } from "~utils/__deprecated__/checkHasLength";

import { StoryblokEditable } from "~components/StoryblokEditable";

import { AccordionBase } from "../AccordionBase";
import { AccordionRichTextItem } from "./_AccordionRichTextItem";
import * as styles from "./styles.css";

import type { AccordionBaseProps } from "../AccordionBase";
import type { AccordionRichTextItemProps } from "./_AccordionRichTextItem";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AccordionRichTextProps
  extends GetSprinklesArgs,
    StoryblokBlok,
    AccordionBaseProps {
  accordionItems: Array<
    Omit<AccordionRichTextItemProps, "index"> & StoryblokBlok
  >;
  className?: string;
  eventNamespace?: string;
  onValueChange?(value: string): void;
  shouldShowNumbers?: boolean;
}
export function AccordionRichText({
  accordionItems,
  className: userClassName,
  defaultValue = "0",
  onValueChange,
  shouldShowNumbers,
  ...rest
}: AccordionRichTextProps) {
  const { atomProps } = extractAtomsFromProps(rest, getSprinkles);
  const rootClassNames = clsx(
    userClassName,
    styles.root,
    getSprinkles(atomProps)
  );

  if (!checkHasLength(accordionItems)) return null;

  return (
    <AccordionBase
      className={rootClassNames}
      collapsible
      onValueChange={onValueChange}
      defaultValue={defaultValue}
      {...rest}
    >
      {accordionItems.map((item, index) => {
        return (
          <StoryblokEditable key={item._uid} {...item}>
            <AccordionRichTextItem
              bloks={item.bloks}
              buttonLink={item.buttonLink}
              description={item.description}
              index={index}
              key={item._uid}
              shouldShowNumbers={shouldShowNumbers}
              tickList={item.tickList}
              title={item.title}
            />
          </StoryblokEditable>
        );
      })}
    </AccordionBase>
  );
}
